<template>
  <div class="telephone-wrap">
    <div class="header">
      <!-- 需校验注册手机号为本人实名开户手机号 -->
      需校验注册手机号为本人身份证办理
    </div>
    <div class="content">
      <div class="label">手机号</div>
      <van-field v-model="telephone" disabled placeholder="请输入手机号" oninput="value= value.replace(/[^\d]/g, '')"
        maxlength="11" :border="false">
        <template #button>
          <div class="send-code" v-if="!isSend" @click="sendCode">
            获取验证码
          </div>
          <div class="send-code2" v-else>重新发送（{{ num }}）</div>
        </template>
      </van-field>
      <div class="label">短信验证码</div>
      <van-field v-model="verifyCode" placeholder="请输入验证码" oninput="value= value.replace(/[^\d]/g, '')" maxlength="6"
        :border="false" />
    </div>
    <div class="footer" v-if="!isPopup">
      <template v-if="canClick">
        <div class="btn" @click="threeElements">确定</div>
      </template>
      <template v-else>
        <div class="btn btn2" @click="threeElements">确定</div>
      </template>
      <div class="des">
        收不到短信验证码？
        <span @click="goHome">尝试其他认证方式</span>
      </div>
    </div>
  </div>
</template>
<script>
import Api from '@/api'
import { Dialog, Toast } from 'vant'

export default {
  props: ['isPopup'],
  data() {
    return {
      sendCodeLoading: false,
      telephone: '',
      verifyCode: '',
      num: 60,
      isSend: false,
      loading: false,
    }
  },
  computed: {
    canClick() {
      if (this.telephone && this.verifyCode) return true
      return false
    },
  },
  created() {
    this.telephone = this.$store.state.creditMsg.telephone
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer)
  },
  methods: {
    goHome() {
      this.$router.push('/auth')
    },
    threeElements() {
      const { creditMsg } = this.$store.state
      if (this.loading) return
      if (!this.$checkPhone(this.telephone)) {
        Toast('请输入正确的手机号')
        return
      }
      this.loading = true
      Api.threeElements({
        telephone: this.telephone,
        verifyCode: this.verifyCode,
        name: creditMsg.userFullName,
        idCardNo: creditMsg.userIdCardNo,
        userId: creditMsg.userId,
      })
        .then(async res => {
          await this.$store.dispatch('fetchUserInfo')
          const { redirectUrl } = this.$route.query

          await this.$store.dispatch('fetchUserInfo')
          this.$router.push({
            path: '/authenticationSuccess',
            query: {
              redirectUrl,
            },
          })
        })
        .catch(err => {
          console.log('err', err)
          if (err.status === 203) {
            Toast(err.msg)
          } else {
            this.$router.push({
              path: '/authenticationError',
              query: {
                type: 'tel',
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendCode() {
      if (this.sendCodeLoading) return
      if (!this.$checkPhone(this.telephone)) {
        Toast('请输入正确的手机号')
        return
      }
      this.sendCodeLoading = true
      Api.getTelCode({
        telephone: this.telephone,
        type: 2,
      })
        .then(res => {
          this.setCode()
        })
        .finally(() => {
          this.sendCodeLoading = false
        })
    },
    setCode() {
      this.isSend = true
      this.timer = setInterval(() => {
        if (!this.num) {
          clearInterval(this.timer)
          this.num = 60
          this.isSend = false
          return
        }
        this.num -= 1
      }, 1000)
    },
  },
}
</script>
<style lang="less" scoped>
.telephone-wrap {
  width: 100%;

  .header {
    width: 100%;
    font-size: 14px;
    color: #f15643;
    line-height: 20px;
    padding: 16px;
  }

  .content {
    padding: 0 16px;

    .label {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(17, 26, 52, 1);
      line-height: 22px;
      margin-bottom: 8px;
    }

    /deep/.van-cell {
      padding: 13px 0;
      height: 48px;
      margin-bottom: 24px;
    }

    .send-code {
      height: 18px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(22, 118, 255, 1);
      line-height: 18px;
    }

    .send-code2 {
      height: 18px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(109, 119, 144, 1);
      line-height: 18px;
    }
  }

  .footer {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    width: 375px;
    height: 102px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    padding-top: 10px;

    .btn {
      width: 327px;
      height: 44px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 44px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 16px;
    }

    .btn2 {
      background: rgba(22, 118, 255, 0.2);
    }

    .des {
      height: 16px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(109, 119, 144, 1);
      line-height: 16px;
      text-align: center;

      span {
        color: rgba(22, 118, 255, 1);
      }
    }
  }
}

.van-cell {
  border-bottom: 1px solid @BORDER-COLOR-0;
}
</style>
